import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ArtistImageSliceData } from "../../redux/artistImageDataSlice";
import loading from "../../assets/loading.gif";
import { bannerLoader } from "../../redux/bannerImages";
import { addFavoriteArtist } from "../../AxiosFunctions/Axiosfunctionality";
import { updateMessage, updateOpen } from "../../redux/message";

function Artists(props) {
  const { agentData } = props;
  const dispatch = useDispatch();
  const { artistImageDataSlice } = useSelector((state) => state);

  const [filterCond, setFilterCond] = useState(true);
  const [allArtists, setAllArtists] = useState([]);
  const [tempArtist, setTempArtist] = useState([]);
  const [filterHighlighted, setFilterHighlighted] = useState(null);
  const [favorites, setFavorites] = useState([]);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  
  useEffect(() => {
    // Sync favorites state with agentData
    if (agentData?.favoriteArtists) {
      setFavorites(agentData.favoriteArtists);
    }
  }, [agentData]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const filterChange = (filter) => {
    if (filter === "A-Z") {
      let temp = [];
      setFilterCond(false);
      let tempImage = [...artistImageDataSlice.artistImages];
      temp = tempImage.sort((a, b) =>
        a.artistId.lastname
          .normalize()
          .localeCompare(b.artistId.lastname.normalize())
      );
      setFilterHighlighted(2);
      setTempArtist(temp);
    } else {
      setFilterHighlighted(1);
      setFilterCond(true);
    }
  };

  useEffect(() => {
    if (artistImageDataSlice.artistImages.length !== 0) {
      setAllArtists(artistImageDataSlice.artistImages);
    }
  }, [artistImageDataSlice.artistImages]);

  useEffect(() => {
    if (artistImageDataSlice.artistImages.length === 0) {
      dispatch(ArtistImageSliceData());
    }
    dispatch(bannerLoader());
  }, []);

  const updateTempArtist = (e) => {
    if (artistImageDataSlice.artistImages.length) {
      const searchValue = e.toLowerCase();
      const temp = artistImageDataSlice.artistImages.filter(
        (element) =>
          element.artistId.firstname.toLowerCase().includes(searchValue) ||
          element.artistId.lastname.toLowerCase().includes(searchValue)
      );
      setFilterHighlighted(null);
      setTempArtist(temp);
    }
  };

  useEffect(() => {
    updateTempArtist(props.searchArtist);
  }, [artistImageDataSlice, props.searchArtist]);

  const addtoFavorite = async (e, artistId) => {
    e.preventDefault();
    try {
      dispatch(updateOpen(true));

      dispatch(updateMessage("Added to favorites!"));
      e.currentTarget.querySelector("img").src =
        "/assets/images/redFavorite.svg";
      await addFavoriteArtist({ _id: agentData?._id, artistId });
      setFavorites([...favorites, artistId]); // Update favorites locally
    } catch (error) {
      console.log(error);
      dispatch(updateMessage("Failed to add to favorites!"));
    }
  };

  return (
    <>
      <div className="sortingcont right pt-0 me-0">
        <div className="d-flex">
          <a
            className={
              filterHighlighted === 1
                ? "filter-button sort-active w-inline-block mt-0"
                : filterHighlighted === 2
                ? "filter-button w-inline-block mt-0"
                : "filter-button sort-active w-inline-block mt-0"
            }
            style={{ marginLeft: "0px" }}
            onClick={() => filterChange("Default")}
          >
            <div>FEATURED</div>
          </a>
          <a
            className={
              filterHighlighted === 2
                ? "filter-button sort-active mt-0 me-0"
                : "filter-button mt-0 me-0"
            }
            onClick={() => filterChange("A-Z")}
          >
            <div>ALPHABETICAL A-Z</div>
          </a>
        </div>
      </div>
      <div className="_2cols" style={{ clear: "both" }}>
        {props.children}
        <div
          id="w-node-a284be2a-4b91-3177-03eb-6614b24879c1-4bf2d022"
          className="_4cols-v2"
          style={
            props.searchArtist !== ""
              ? { gridTemplateRows: windowSize.innerWidth > 600 ? "50px repeat(100,auto)" : ""}
              : {}
          }
        >
          {props.searchArtist ? (
            <h2 className="newsh2 h" style={{ gridColumn: "1/7" }}>
              SEARCH RESULTS
            </h2>
          ) : null}
          {artistImageDataSlice.loading && allArtists.length === 0 ? (
            <div style={{ position: "absolute", top: "50%", left: "50%" }}>
              <img className="mb-3" alt="loading" src={loading} />
            </div>
          ) : artistImageDataSlice.artistImages &&
            props.searchArtist === "" &&
            filterCond ? (
            allArtists.map((val) => (
              <Link
                id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                to={val.artistId.fullName}
                key={val.artistId._id}
                className="artistcard"
              >
                <img
                  src={String(val.mainImage[0].subImage[0].path)}
                  loading="lazy"
                  alt=""
                  className="image"
                />
                <div className="artistnamediv">
                  <div className="artistnametext-v3">
                    {val.artistId.firstname} {val.artistId.lastname}
                  </div>
                </div>
                {agentData &&
                  agentData?._id &&
                  !favorites.includes(val.artistId._id) && (
                    <div className="favButtonHomeContainer">
                      <div
                        onClick={(e) => addtoFavorite(e, val.artistId._id)}
                        className="favoriteButtonHomeDiv"
                        style={{
                          color: "gray",
                        }}
                      >
                        <img
                          src={"/assets/images/favorite.svg"}
                          alt="Favorite Icon"
                        />
                      </div>
                    </div>
                  )}
              </Link>
            ))
          ) : (
            tempArtist.map((val) => (
              <Link
                id="w-node-a284be2a-4b91-3177-03eb-6614b24879c7-4bf2d022"
                data-w-id="a284be2a-4b91-3177-03eb-6614b24879c7"
                to={val.artistId.fullName}
                className="artistcard"
              >
                <img
                  src={String(val.mainImage[0].subImage[0].path)}
                  loading="lazy"
                  alt=""
                  className="image"
                />
                <div className="artistnamediv">
                  <div className="artistnametext-v3">
                    {val.artistId.firstname} {val.artistId.lastname}
                  </div>
                </div>
                {agentData &&
                  agentData?._id &&
                  !favorites.includes(val.artistId._id) && (
                    <div className="favButtonHomeContainer">
                      <div
                        onClick={(e) => addtoFavorite(e, val.artistId._id)}
                        className="favoriteButtonHomeDiv"
                        style={{
                          color: "gray",
                        }}
                      >
                        <img
                          src={"/assets/images/favorite.svg"}
                          alt="Favorite Icon"
                        />
                      </div>
                    </div>
                  )}
              </Link>
            ))
          )}
        </div>
      </div>
    </>
  );
}

export default Artists;
