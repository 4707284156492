import { useEffect, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import BackIconForm from "../../../assets/svgs/backArrow.svg";
import AddToMoodBoardButton from "../agentUser/addToMoodBoardButton";

const TabAwardPress = ({ awardPress, agentData, artistID }) => {
  const { search } = useParams();
  const { pages } = useParams();
  const history = useHistory();
  const queryParams = new URLSearchParams(history.location.search);
  const id = queryParams.get("id");
  const [level, setLevel] = useState(0);
  const [lineCarrousel, setLineCarrousel] = useState(0);
  const [awardPressModal, setAwardPressModal] = useState(false);
  const [carrouselIndex, setCarrouselIndex] = useState(0);
  const [currentCarrousel, setCurrentCarrousel] = useState();
  const [displayCss, setDisplayCss] = useState(0);
  const [awards, setAwards] = useState(
    awardPress.filter((award) => award.awardType === "award")
  );
  const [press, setPress] = useState(
    awardPress.filter((award) => award.awardType === "press")
  );
  const [currentAwardPress, setCurrentAwardPress] = useState();

  useEffect(() => {
    if (search === "awards&press" && id) {
      const award = awardPress.find((award) => award.awardPressId === id);
      setLevel(1);
      setCurrentAwardPress(award);
    } else {
      setLevel(0);
      setCurrentAwardPress(null);
    }
  }, [id]);

  const moveCarrousel = (moveTo, index) => {
    const carrouselLengh =
      currentAwardPress.extraProperties[index].categoryValueProperty.length / 5;
    const newDisplayCarrousel = lineCarrousel + moveTo;

    if (newDisplayCarrousel >= carrouselLengh) {
      setLineCarrousel(0);
      setDisplayCss(0);
    } else if (newDisplayCarrousel < 0) {
      setLineCarrousel(carrouselLengh - 1);
      setDisplayCss((carrouselLengh - 1) * 14);
    } else {
      setLineCarrousel(newDisplayCarrousel);
      setDisplayCss(newDisplayCarrousel * 14);
    }
  };

  const moveCarrouselPopUp = (moveTo) => {
    const newIndex = carrouselIndex + moveTo;
    if (
      newIndex >=
      currentAwardPress.extraProperties[currentCarrousel].categoryValueProperty
        .length
    ) {
      setCarrouselIndex(0);
    } else if (newIndex < 0) {
      setCarrouselIndex(
        currentAwardPress.extraProperties[currentCarrousel]
          .categoryValueProperty.length - 1
      );
    } else {
      setCarrouselIndex(newIndex);
    }
  };

  const handleCarousel = (i, index) => {
    setAwardPressModal(true);
    setCurrentCarrousel(i);
    setCarrouselIndex(index);
  };

  const handleLevelChange = (level, award) => {
    window.scrollTo(0, 0);
    if (level === 1) {
      history.push(`/${pages}/${search}?id=${award.awardPressId}`);
    } else if (level === 0) {
      history.push(`/${pages}/${search}`);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      {level === 0 && (
        <>
          <h3 className="homeh3 pwh3sub" style={{ marginBottom: "15px" }}>
            SELECT AWARD / PRESS
          </h3>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
            }}
          >
            <h3 className="homeh3 pwh3">AWARDS</h3>
            <div className="separatorPw"></div>
          </div>
          <div className="detail_card2 pwCategpryCont">
            {awards.map((award, i) => (
              <div
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => handleLevelChange(1, award)}
              >
                <div className="detail_card6_h">
                  <img
                    className="image"
                    src={award.awardMainImage}
                    alt={award.awardTitle}
                  />
                </div>
                <label className="pwLabel">{award.awardTitle}</label>
              </div>
            ))}
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
            }}
          >
            <h3 className="homeh3 pwh3">PRESS</h3>
            <div className="separatorPw"></div>
          </div>
          <div className="detail_card2 pwCategpryCont">
            {press.map((press, i) => (
              <div
                key={i}
                style={{ cursor: "pointer" }}
                onClick={() => handleLevelChange(1, press)}
              >
                <div className="detail_card6_h">
                  <img
                    className="image"
                    src={press.awardMainImage}
                    alt={press.awardTitle}
                  />
                </div>
                <label className="pwLabel">{press.awardTitle}</label>
              </div>
            ))}
          </div>
        </>
      )}
      {level === 1 && (
        <div className="main-container-awards">
          <div style={{ display: "flex", justifyContent: "end" }}>
            <img
              onClick={() => handleLevelChange(0, null)}
              alt="back"
              src={BackIconForm}
              style={{ width: "40px", cursor: "pointer" }}
            />
          </div>
          <div className="awardsWithImage">
            <div style={{position:"relative"}}>
              <img
                src={currentAwardPress.awardMainImage}
                alt={currentAwardPress.awardTitle}
              />
              <AddToMoodBoardButton
              agentData={agentData}
              artistImage={currentAwardPress.awardMainImage}
              artistImageLow={currentAwardPress.awardMainImage}
              artistID={artistID}
              />
            </div>
            <div className="awardContainer">
              {currentAwardPress.awardType === "award" &&
              currentAwardPress.awardTitle !== "" ? (
                <>
                  <h4 className="pwh4 h4CommonStyle">Title:</h4>
                  <h4 className="pwh4 h4CommonStyle">
                    {currentAwardPress.awardTitle}
                  </h4>
                </>
              ) : (
                currentAwardPress.awardType === "press" &&
                currentAwardPress.awardSource !== "" && (
                  <>
                    <h4 className="pwh4 h4CommonStyle">Source:</h4>
                    <h4 className="pwh4 h4CommonStyle">
                      {currentAwardPress.awardSource}
                    </h4>
                  </>
                )
              )}

              {currentAwardPress.awardType === "press" &&
                currentAwardPress.awardTitle !== "" && (
                  <>
                    <h4 className="pwh4 h4CommonStyle">Title:</h4>
                    <p className="pwP">{currentAwardPress.awardTitle}</p>
                  </>
                )}

              {currentAwardPress.awardAuthor !== "" && (
                <>
                  <h4 className="pwh4 h4CommonStyle">Author:</h4>
                  <p className="pwP">{currentAwardPress.awardAuthor}</p>
                </>
              )}
              {currentAwardPress.awardIllustrator !== "" && (
                <>
                  <h4 className="pwh4 ">Illustrator:</h4>
                  <p className="pwP">{currentAwardPress.awardIllustrator}</p>
                </>
              )}

              {currentAwardPress.awardDate !== "" && (
                <>
                  <h4 className="pwh4 ">
                    {currentAwardPress.awardType === "press"
                      ? "Date of Article:"
                      : "Date of Award:"}
                  </h4>
                  <p className="pwP">{currentAwardPress.awardDate}</p>
                </>
              )}

              {currentAwardPress.awardArtistContributed !== "" && (
                <>
                  <h4 className="pwh4 ">Artwork Contributed:</h4>
                  <p className="pwP">
                    {currentAwardPress.awardArtistContributed}
                  </p>
                </>
              )}

              {currentAwardPress.awardLink !== "" && (
                <p className="awardLinkStyle">
                  Source / Site:{" "}
                  <a href={currentAwardPress.awardLink} target="_blank">
                    Click here
                  </a>
                </p>
              )}
            </div>
          </div>
          {currentAwardPress.extraProperties[0].categoryValueProperty && (
            <div style={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
              <h3 className="homeh3 pwh3">Additional Information</h3>
              <div className="separatorPw"></div>
            </div>
          )}
          {currentAwardPress.extraProperties[0].categoryValueProperty && (
            <div style={{ width: "100%", marginBottom: "30px" }}>
              {currentAwardPress.extraProperties.map((extra, i) => {
                //---------------------------------------------------------TEXT SECTION
                if (extra.categoryKeyProperty === "text") {
                  return (
                    <div
                      key={i}
                      className="BioDetailDescriptionPortfolio"
                      dangerouslySetInnerHTML={{
                        __html: extra.categoryValueProperty,
                      }}
                      style={{ marginBottom: "30px", marginTop: "30px" }}
                    ></div>
                  );
                }
                //---------------------------------------------------------BANNER SECTION
                if (extra.categoryKeyProperty === "banner") {
                  return (
                    <div
                      key={i}
                      style={{ marginBottom: "30px", marginTop: "30px" }}
                    >
                      <img src={extra.categoryValueProperty}></img>
                    </div>
                  );
                }
                //---------------------------------------------------------CARROUSEL SECTION
                if (extra.categoryKeyProperty === "carousel") {
                  return (
                    <>
                      <div
                        style={{
                          height: "14vh",
                          width: "100%",
                          display: "flex",
                          marginBottom: "30px",
                        }}
                      >
                        <img
                          src="/assets/images/left.png"
                          alt="leftArrow"
                          className="carouselArrow"
                          style={{ alignSelf: "center", cursor: "pointer" }}
                          onClick={() => moveCarrousel(-1, i)}
                        />
                        <div
                          style={{
                            height: "14vh",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            className="pwSecImgCont"
                            style={{
                              translate: `0px calc((${displayCss}vh + ${
                                lineCarrousel * 20
                              }px)*-1)`,
                            }}
                          >
                            {extra.categoryValueProperty.map((img, index) => {
                              return (
                                <div
                                  key={index}
                                  onClick={() => handleCarousel(i, index)}
                                  style={{
                                    cursor: "pointer",
                                    backgroundImage: `url(${img})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    height: "14vh",
                                    backgroundPosition: "center",
                                  }}
                                ></div>
                              );
                            })}
                          </div>
                        </div>
                        <img
                          src="/assets/images/right.png"
                          alt="rightArrow"
                          className="carouselArrow"
                          style={{ alignSelf: "center", cursor: "pointer" }}
                          onClick={() => moveCarrousel(1, i)}
                        />
                        {awardPressModal && (
                          <>
                            <div className="modalTutorial">
                              <div className="containerModal2">
                                <div className="modalCloseBtn">
                                  <button
                                    onClick={() => setAwardPressModal(false)}
                                  >
                                    ×
                                  </button>
                                </div>
                                <div
                                  style={{
                                    maxHeight: "90vh",
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    position:"relative",
                                    maxWidth: "93%",
                                  }}
                                >
                                  <img
                                    src="/assets/images/left.png"
                                    alt="leftArrow"
                                    style={{
                                      maxWidth: "40px",
                                      width: "6%",
                                      alignSelf: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => moveCarrouselPopUp(-1)}
                                  />
                                  <img
                                    src={
                                      currentAwardPress.extraProperties[
                                        currentCarrousel
                                      ].categoryValueProperty[carrouselIndex]
                                    }
                                    alt="secondaryImage"
                                    style={{
                                      maxHeight: "90vh",
                                      maxWidth: "85%",
                                    }}
                                  />
                                  <img
                                    src="/assets/images/right.png"
                                    alt="rightArrow"
                                    style={{
                                      maxWidth: "40px",
                                      width: "6%",
                                      alignSelf: "center",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => moveCarrouselPopUp(1)}
                                  />
                                  <AddToMoodBoardButton
                                  agentData={agentData}
                                  artistImage={currentAwardPress.extraProperties[currentCarrousel].categoryValueProperty[carrouselIndex]}
                                  artistImageLow={currentAwardPress.extraProperties[currentCarrousel].categoryValueProperty[carrouselIndex]}
                                  artistID={artistID}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TabAwardPress;
