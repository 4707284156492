import React, { useEffect, useState } from "react";

import "react-quill/dist/quill.snow.css";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { updateClientData } from "../../AxiosFunctions/Axiosfunctionality";
import Input from "../../components/input/input";
import { updateMessage, updateOpen } from "../../redux/message";
import "./admin.css";
import ClientsList from "./clientsPages/clientsList";
import Edit from "./clientsPages/edit";

function Clients(props) {
  const historyCurrent = useHistory();

  const dispatch = useDispatch();
  const [formNo2, setFormNo2] = useState(2);
  const [search, setSearch] = useState("");
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedImages, setSelectedImages] = useState({});

  const [holder, setHolder] = useState(false);
  const [tempClient, setTempClient] = useState([]);

  useEffect(() => {
    if (historyCurrent.location.state) {
      checkCurrentUserLocalStorage();
      formChangeEvent(historyCurrent.location.state, true);
      return;
    }
    checkCurrentUserLocalStorage();
  }, []);

  const checkCurrentUserLocalStorage = () => {
    const curr = JSON.parse(localStorage.getItem("currentClient"));
    if (curr) {
      setSelectedClient(curr);
    }
  };

  const formChangeEvent = (data, state = false) => {
    props.setFormNo(1);
    if (state) {
      setFormNo2(2);
    } else {
      setFormNo2(2);
    }
    if (!data._id) {
      checkCurrentUserLocalStorage();
      return;
    }
    localStorage.setItem("currentClient", JSON.stringify(data));
    setSelectedImages([]);

    setSelectedClient(data);

    setSearch("");
  };

  const updateClient = (e, data) => {
    setHolder(true);
    updateClientData(data).then((res) => {
      setHolder(false);
      dispatch(updateOpen(true));
      dispatch(updateMessage(res));
      props.populateClientUsers();
      props.setFormNo(0);
      setFormNo2(0);
    });
  };

  const changeClientData = (e) => {
    setSearch(e.target.value);
    const searchvalue = e.target.value.toLowerCase();
    setTempClient(
      props.clientUsers.filter(function (element) {
        return (
          element.firstname.toLowerCase().includes(searchvalue) ||
          element.lastname.toLowerCase().includes(searchvalue) ||
          element.occupation.toLowerCase().includes(searchvalue) ||
          element.company.toLowerCase().includes(searchvalue) ||
          (element.status === 1 && searchvalue.includes("active")) ||
          (element.status === 0 && searchvalue.includes("inactive"))
        );
      })
    );
  };

  return (
    <div className="px-xl-5 mx-xl-5">
      <div
        className={
          "mx-lg-5 px-lg-3 pb-4 ml-5 d-flex flex-column" +
          (props.formNo === 1 ? " align-items-center" : "")
        }
      >
        {props.formNo === 1 ? (
          <>
            <h4>
              {selectedClient.lastname} {selectedClient.firstname}
            </h4>
            <div className="col-6 p-0 subNavBar d-flex justify-content-between">
              <button
                onClick={() => setFormNo2(3)}
                className={"btn" + (formNo2 === 3 ? " active" : "")}
              >
                Edit
              </button>
            </div>
          </>
        ) : (
          <div className="mx-lg-5 px-lg-3 py-4 mt-3 d-flex justify-content-between">
            <h4>CLIENTS / PROPERTY ACCESS</h4>
            <Input
              type="search"
              label="Search"
              value={search}
              onChange={(e) => {
                changeClientData(e);
              }}
            />
          </div>
        )}
      </div>
      <div className="px-3 mx-5">
        {props.formNo === 1 ? (
          formNo2 === 3 ? (
            <Edit
              holder={holder}
              selectedClient={selectedClient}
              setFormNo={(e) => props.setFormNo(e)}
              setFormNo2={(e) => setFormNo2(e)}
              updateClient={updateClient}
            />
          ) : (
            <Edit
              holder={holder}
              selectedClient={selectedClient}
              setFormNo={(e) => props.setFormNo(e)}
              setFormNo2={(e) => setFormNo2(e)}
              updateClient={updateClient}
            />
          )
        ) : (
          <ClientsList
            search={search}
            tempClient={tempClient}
            holder={holder}
            clientUsers={props.clientUsers}
            deleteClientUser={props.deleteClientUser}
            formChangeEvent={(e) => formChangeEvent(e)}
            reorderClientHandler={props.reorderClientHandler}
          />
        )}
      </div>
    </div>
  );
}

export default Clients;
