import React, { useEffect, useState } from "react";
import {
  addItemToMoodBoard,
  addItemWithValueToMoodBoard,
  addMoodBoardItemValue,
  addShareEditEmailToMoodBoard,
  apiCallToGeneratePDF,
  createMoodBoard,
  deleteMoodBoardItem,
  getMoodBoardsSharedWithMe,
  updateMoodBoard,
  updateShareEditEmailToMoodBoard,
  uploadFiles,
} from "../../../AxiosFunctions/Axiosfunctionality";
import { AgentDataAPI } from "../../../redux/agentSlice";
import { useDispatch } from "react-redux";
import { updateMessage, updateOpen } from "../../../redux/message";
import leftArrow from "../../../assets/svgs/leftArrow.svg";
import rightArrowCircle from "../../../assets/svgs/rightArrowCircle.svg"; // Import your SVG file
import leftArrowCircle from "../../../assets/svgs/leftArrowCircle.svg"; // Import your SVG file
import downArrow from "../../../assets/svgs/downArrow.svg"; // Import your SVG file
import upArrow from "../../../assets/svgs/upArrow.svg"; // Import your SVG file
import crossSvg from "../../../assets/svgs/crossButton.svg"; // Import your SVG file
import columns2Icon from "../../../assets/svgs/2columns.svg"; // Import your SVG file
import columns3Icon from "../../../assets/svgs/3columns.svg"; // Import your SVG file
import elementLibraryIcon from "../../../assets/svgs/elementaryLibrary.svg"; // Import your SVG file
import photoIcon from "../../../assets/svgs/photo.svg"; // Import your SVG file
import columns4Icon from "../../../assets/svgs/4columns.svg"; // Import your SVG file
import textIcon from "../../../assets/svgs/text.svg"; // Import your SVG file
import MyPopup from "../../../components/myPopup/myPopup";
import copyButton from "../../../assets/svgs/copyIcon.svg"; // Import your SVG file
import editButton from "../../../assets/svgs/edit.svg"; // Import your SVG file
import imageUploadIcon from "../../../assets/svgs/imageUpload.svg"; // Import your SVG file
import ReactQuill from "react-quill";
import loadingUpdate from "../../../assets/loading_trasnparent.gif";
import expandIcon from "../../../assets/img/expand.svg";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AddToMoodBoardButton = ({
  agentData,
  artistImage,
  artistID,
  artistImageLow,
}) => {
  const myIdeaBoardsLimit = 5;
  const [showMoreIdeaBoards, setShowMoreIdeaBoards] = useState(false);
  const [levelmoodboardPopup, setLevelmoodboardPopup] = useState(0);
  const [moodboardPopup, setMoodboardPopup] = useState(false);
  const [newmoodboardName, setNewMoodBoardName] = useState("");
  const [selectedMoodboard, setSelectedMoodboard] = useState(null);
  const [showSecondDropdown, setShowSecondDropdown] = useState(false);
  const [sharedMoodBoards, setSharedMoodBoards] = useState([]);
  const [reverseMoodboards, setReverseMoodboards] = useState([]);
  const [orderMode, setOrderMode] = useState(false);
  const [loaderPDF, setLoaderPDF] = useState(false);
  const [editAccess, setEditAccess] = useState(false);
  const [removingEmailIndex, setRemovingEmailIndex] = useState(null);
  const [shareModalActive, setShareModalActive] = useState(false);
  const [addingAgent, setAddingAgent] = useState(false);
  const [shareFailed, setShareFailed] = useState("");
  const [shareSuccess, setShareSuccess] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [copySuccess, setCopySuccess] = useState("");
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [selectedMoodBoardItem, setSelectedMoodBoardItem] = useState("");
  const [textElement, setTextElement] = useState("");

  const dispatch = useDispatch();
  const baseUrl = window.location.origin;
  const history = useHistory();

  useEffect(() => {
    if(agentData && agentData?.moodBoards?.length > 0){
      const rMoodboards = [...agentData.moodBoards].reverse();
      setReverseMoodboards(rMoodboards);
    }
  }, [agentData]);
  const handleNewMoodboard = (event) => {
    setNewMoodBoardName(event.target.value);
  };
  const handleCreateMoodboard = async () => {
    // Simulate API call to create a new moodboard

    try {
      await createMoodBoard({
        userId: agentData._id,
        title: newmoodboardName,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setNewMoodBoardName("");

      setLevelmoodboardPopup(0);
    } catch (error) {
      console.error("Failed to create moodBoard", error);
    }

    setNewMoodBoardName("");
    setLevelmoodboardPopup(0);
  };

  const removeValueFromMoodItem = async (itemId, prevValue, indexToRemove) => {
    if (prevValue && indexToRemove >= 0 && indexToRemove < prevValue.length) {
      // Remove the item at the specified index
      let newValue = [
        ...prevValue.slice(0, indexToRemove),
        ...prevValue.slice(indexToRemove + 1),
      ];

      try {
        setSelectedMoodboard((prev) => ({
          ...prev,
          moodBoardItems: prev.moodBoardItems.map((item) =>
            item._id === itemId ? { ...item, itemValue: newValue } : item
          ),
        }));

        const response = await addMoodBoardItemValue({
          userId: agentData._id,
          moodBoardId: selectedMoodboard._id,
          itemId: itemId,
          itemValue: newValue,
        });
        await dispatch(AgentDataAPI({ _id: agentData._id }));
        await fetchSharedIdeaBoards();
        dispatch(updateOpen(true));
        dispatch(updateMessage("Item Successfully Removed"));
      } catch (error) {
        console.error("Error removing item", error);
      }
    } else {
      console.error("Invalid index or empty previous value");
    }
  };

  const handleGoToIdeaBoard = () => {
    const newRoute = `/myshannon/${agentData._id}/?activeTab=${
      !selectedMoodboard.sharedByUserId ? "ideaBoard" : "sharedIdeaBoard"
    }&ideaBoardId=${selectedMoodboard._id}`;
    history.push(newRoute);
  };
  useEffect(() => {
    if (
      selectedMoodboard &&
      selectedMoodboard._id &&
      agentData &&
      agentData.moodBoards &&
      agentData.moodBoards.length > 0
    ) {
      let matchingMoodBoard = agentData.moodBoards.find(
        (moodBoard) => moodBoard._id === selectedMoodboard._id
      );

      // If no matching mood board is found in agentData, search in sharedMoodBoards
      if (
        !matchingMoodBoard &&
        sharedMoodBoards &&
        sharedMoodBoards.length > 0
      ) {
        matchingMoodBoard = sharedMoodBoards.find(
          (moodBoard) => moodBoard._id === selectedMoodboard._id
        );
      }
      // Check if a matching mood board was found
      if (matchingMoodBoard) {
        setSelectedMoodboard(matchingMoodBoard); // Update the selectedMoodboard with the found mood board
      }
    }
  }, [agentData, sharedMoodBoards]);

  const handleSelectedMoodboard = (moodboard) => {
    setSelectedMoodboard(moodboard);
    setLevelmoodboardPopup(2);
  };
  const handleSelectedElement = async (element) => {
    const userId = selectedMoodboard?.sharedByUserId || agentData._id;
    try {
      const response = await addItemToMoodBoard({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        itemType: element,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setLevelmoodboardPopup(2);
    } catch (error) {
      console.error("Error adding new element", error);
    }
    setShowSecondDropdown(false);
  };
  const handlePDFResponse = (response) => {
    try {
      if (response && response.data) {
        // Convert the response data to a blob (PDF data)
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });
        /* console.log(pdfBlob); // Optional: Log to check the content */

        // Create a URL from the blob and create a temporary download link
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Create a temporary download link and trigger the download
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "mood-board.pdf"; // Customize the filename if needed
        link.click(); // Trigger the download
      } else {
        console.error("Failed to generate PDF: No data in response");
      }
    } catch (error) {
      console.error("Error handling the PDF response:", error);
    }
  };
  const handleDownloadPDF = async () => {
    try {
      setLoaderPDF(true);
      // Call the backend API to generate the PDF
      const response = await apiCallToGeneratePDF({
        userId: agentData._id, // Assuming you already have the user ID and moodBoardId
        moodBoardId: selectedMoodboard._id,
      });

      // Handle the response (PDF blob) and trigger the download
      handlePDFResponse(response);
      setLoaderPDF(false);
    } catch (error) {
      console.error("Error during the PDF download process:", error);
    }
  };
  const handleCopyLink = () => {
    // The value you want to copy
    let urlToCopy = `${baseUrl}/myshannon/${agentData._id}/?activeTab=ideaBoard&ideaBoardId=${selectedMoodboard._id}`;

    // Using the Clipboard API to copy the text
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setCopySuccess("Link copied successfully!");
        // Hide the message after 2 seconds
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopySuccess("Failed to copy text.");
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };
  const handleCopyLinkShare = () => {
    // The value you want to copy
    let urlToCopy = `${baseUrl}/myshannon/${agentData._id}/?activeTab=sharedIdeaBoard&ideaBoardId=${selectedMoodboard._id}`;

    // Using the Clipboard API to copy the text
    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => {
        setCopySuccess("Link copied successfully!");
        // Hide the message after 2 seconds
        setTimeout(() => setCopySuccess(""), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        setCopySuccess("Failed to copy text.");
        setTimeout(() => setCopySuccess(""), 2000);
      });
  };

  const handleRemoveAgent = async (index) => {
    setRemovingEmailIndex(index);
    const updatedEmails = [...(selectedMoodboard.sharedEditEmails || [])];
    // Remove the email at the specified index
    updatedEmails.splice(index, 1);
    try {
      const response = await updateShareEditEmailToMoodBoard({
        userId: agentData._id,
        moodBoardId: selectedMoodboard._id,
        emails: updatedEmails,
      });

      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();
    } catch (error) {
      console.error("Error adding new element", error);
    } finally {
      setRemovingEmailIndex(null);
    }
  };
  const fetchSharedIdeaBoards = async () => {
    try {
      const response = await getMoodBoardsSharedWithMe({
        userId: agentData._id,
      });
      setSharedMoodBoards(response.data.moodBoards);
    } catch (error) {
      console.error("Error fetching shared idea boards", error);
    }
  };
  useEffect(() => {
    if (agentData && agentData._id) {
      fetchSharedIdeaBoards();
    }
  }, [agentData]);

  const renderBannerItem = (element, moodBoardItemIndex) => {
    const image = element.itemValue || null;

    const handleImageUpload = async (event, itemId) => {
      const file = event.target.files[0];

      if (file) {
        setSelectedMoodboard((prev) => ({
          ...prev,
          moodBoardItems: prev.moodBoardItems.map((item) =>
            item._id === itemId
              ? { ...item, itemValue: URL.createObjectURL(file) }
              : item
          ),
        }));

        try {
          const urls = await uploadFiles([file]); // Assumed this returns an array of URLs
          const imageURL = urls[0]; // Assuming you want to append the first URL
          console.log(imageURL);
          const response = await addMoodBoardItemValue({
            userId: agentData._id,
            moodBoardId: selectedMoodboard._id,
            itemId: itemId,
            itemValue: imageURL,
          });
          await dispatch(AgentDataAPI({ _id: agentData._id }));
          await fetchSharedIdeaBoards();
        } catch (error) {
          console.error("Error removing item", error);
        }
      }
    };

    return (
      <div
        key={element._id}
        className="moodBoardElementGrid"
        style={{
          gridTemplateColumns: `repeat(1, 1fr)`,
          width: "95%",
          minHeight: "auto",
        }}
      >
        <div
          className="accessButtonsContainer"
          style={{
            top: "0px",
            right: "-30px",
          }}
        >
          <img
            src={crossSvg}
            alt="Delete"
            className="accessButtons accessButtonMain"
            onClick={() => {
              setSelectedMoodBoardItem(element);
              setDeleteModalActive(true);
            }}
          />
        </div>
        {orderMode && (
          <div className="orderButtonContainer miniVersion">
            <div>
              <img
                src={upArrow}
                alt="up"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, true);
                }}
              />
              <img
                src={downArrow}
                alt="down"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, false);
                }}
              />
            </div>
          </div>
        )}
        {image ? (
          <div className="imageContainer">
            <img src={image} alt="Banner image" className="bannerImage" />

            <div className="accessButtonsContainer">
              <img
                src={crossSvg}
                alt="Delete"
                className="accessButtons"
                onClick={(e) => {
                  e.stopPropagation();
                  removeValueFromMoodItemBanner(element._id);
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className="imagePlaceholder"
            style={{
              minHeight: "200px",
            }}
          >
            <input
              type="file"
              onChange={(e) => handleImageUpload(e, element._id)}
              className="uploadInput"
              id="fileInput" // Added an ID for easier referencing
              accept="image/*" // Optional: Restrict file types
              style={{ display: "none" }} // Hide the input
            />
            <button
              onClick={() => document.getElementById("fileInput").click()}
              className="myBtn active sm"
            >
              Upload Image
            </button>
          </div>
        )}
      </div>
    );
  };
  const handleOrder = () => {
    setOrderMode(true);
  };

  const handleOrderClose = () => {
    setOrderMode(false);
    const originalMoodboard = reverseMoodboards.find(
      (e) => e._id === selectedMoodboard._id
    );
    setSelectedMoodboard(originalMoodboard);
  };

  const handleSaveMoodboard = async () => {
    const userId = selectedMoodboard?.sharedByUserId || agentData._id;
    try {
      await updateMoodBoard({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        moodBoardItems: selectedMoodboard.moodBoardItems,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Changed Successfully"));
      setOrderMode(false);
    } catch (error) {
      console.error("Failed to update mood board", error);
    }
  };

  const addValueToMoodItem = async (itemId, prevValue, index = 0) => {
    const newSingleValue = {
      artistID,
      artistImage,
      artistImageLow,
      index,
    };
    let newValue;
    if (!prevValue) {
      newValue = [newSingleValue];
    } else {
      newValue = [...prevValue, newSingleValue];
    }
    try {
      const userId = selectedMoodboard?.sharedByUserId || agentData._id;

      const response = await addMoodBoardItemValue({
        userId: userId,
        moodBoardId: selectedMoodboard._id,
        itemId: itemId,

        itemValue: newValue,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Successfully Added"));
    } catch (error) {
      console.error("Error updating element", error);
    }
  };
  const handleOpenSecondDropdown = () => {
    setShowSecondDropdown(!showSecondDropdown);
  };
  const handleCloseModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(false);
  };
  const handleToggleModal = () => {
    setLevelmoodboardPopup(0);
    setMoodboardPopup(!moodboardPopup);
  };
  if (!agentData || !agentData._id) {
    return null;
  }

  const handleChangeElementOrder = (index, doUp) => {
    setSelectedMoodboard((prev) => {
      const newMoodBoardItems = [...prev.moodBoardItems];
      const newIndex = doUp ? index - 1 : index + 1;

      if (newIndex >= 0 && newIndex < newMoodBoardItems.length) {
        [newMoodBoardItems[index], newMoodBoardItems[newIndex]] = [
          newMoodBoardItems[newIndex],
          newMoodBoardItems[index],
        ];
      }

      return {
        ...prev,
        moodBoardItems: newMoodBoardItems,
      };
    });
  };

  const renderGridItem = (element, numColumns, moodBoardItemIndex) => {
    const images = element.itemValue || [];
    const changeImageOrder = (imageIndex, direction) => {
      const newImages = [...images];
      if (Number.isInteger(newImages[imageIndex].index)) {
        const t =
          direction === "left"
            ? newImages[imageIndex].index - 1
            : newImages[imageIndex].index + 1;
        if (t >= 0 && t < numColumns) {
          const s = newImages.findIndex((element) => element.index === t);
          newImages[imageIndex] =
            direction === "left"
              ? {
                  ...newImages[imageIndex],
                  index: newImages[imageIndex].index - 1,
                }
              : {
                  ...newImages[imageIndex],
                  index: newImages[imageIndex].index + 1,
                };
          if (s !== -1) {
            newImages[s] =
              direction === "left"
                ? { ...newImages[s], index: newImages[s].index + 1 }
                : { ...newImages[s], index: newImages[s].index - 1 };
          }
        }
        setSelectedMoodboard((prev) => ({
          ...prev,
          moodBoardItems: prev.moodBoardItems.map((el, idx) =>
            idx === moodBoardItemIndex
              ? { ...el, itemValue: [...newImages] }
              : { ...el }
          ),
        }));
      } else {
        const targetIndex =
          direction === "left" ? imageIndex - 1 : imageIndex + 1;

        // Check if the target index is within bounds
        if (targetIndex >= 0 && targetIndex < images.length) {
          // Swap the images
          [newImages[imageIndex], newImages[targetIndex]] = [
            newImages[targetIndex],
            newImages[imageIndex],
          ];
          // Update the state with new images array
          setSelectedMoodboard((prev) => ({
            ...prev,
            moodBoardItems: prev.moodBoardItems.map((el, idx) =>
              idx === moodBoardItemIndex
                ? { ...el, itemValue: [...newImages] }
                : el
            ),
          }));
        }
      }
    };
    // Fill up the placeholders to match the number of columns
    const items = [];
    for (let i = 0; i < numColumns; i++) {
      items.push(
        <div
          key={i}
          className="CustomDropdownItem"
          onClick={() => addValueToMoodItem(element._id, element.itemValue, i)}
        >
          <div
            style={{
              display: "flex",
              height: "100%",
              border: "dashed black",
              padding: "5px 0px 0px 5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p>Add image</p>
          </div>
        </div>
      );
    }
    if (images && images?.length > 0) {
      for (let j = 0; j < images.length; j++) {
        if (Number.isInteger(images[j].index)) {
          items[images[j].index] = (
            <div key={j + Math.random() * 1000} className="imagescontainer">
              <div className="imagescontainer">
                {orderMode && images.length > 1 && (
                  <div className="image-order-arrows miniVersionArrow">
                    <img
                      src={leftArrowCircle}
                      alt="Move left"
                      className=" orderArrow"
                      onClick={(e) => {
                        e.stopPropagation();
                        changeImageOrder(j, "left", numColumns);
                      }}
                    />
                    <img
                      src={rightArrowCircle}
                      alt="Move right"
                      className=" orderArrow"
                      onClick={(e) => {
                        e.stopPropagation();

                        changeImageOrder(j, "right", numColumns);
                      }}
                    />
                  </div>
                )}
                <img
                  onClick={() => {
                    history.push(
                      `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                    );
                  }}
                  className=""
                  src={images[j]?.artistImage}
                  alt={`Grid image ${j + 1}`}
                />
                <div
                  className="artistnametext-v3"
                  onClick={() => {
                    history.push(
                      `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                    );
                  }}
                >
                  {images[j]?.artistDetails?.firstname}{" "}
                  {images[j]?.artistDetails?.lastname}
                </div>
              </div>
              <div className="accessButtonsContainer">
                <img
                  src={crossSvg}
                  alt="Delete"
                  className="accessButtons"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeValueFromMoodItem(element._id, element.itemValue, j);
                  }}
                />
              </div>
            </div>
          );
        } else {
          items[j] = (
            <div key={j + Math.random() * 1000} className="imagescontainer">
              <div className="imagescontainer">
                {orderMode && images.length > 1 && (
                  <div className="image-order-arrows miniVersionArrow">
                    <img
                      src={leftArrowCircle}
                      alt="Move left"
                      className=" orderArrow"
                      onClick={(e) => {
                        e.stopPropagation();
                        changeImageOrder(j, "left", numColumns);
                      }}
                    />
                    <img
                      src={rightArrowCircle}
                      alt="Move right"
                      className=" orderArrow"
                      onClick={(e) => {
                        e.stopPropagation();

                        changeImageOrder(j, "right", numColumns);
                      }}
                    />
                  </div>
                )}
                <img
                  onClick={() => {
                    history.push(
                      `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                    );
                  }}
                  className=""
                  src={images[j]?.artistImage}
                  alt={`Grid image ${j + 1}`}
                />
                <div
                  className="artistnametext-v3"
                  onClick={() => {
                    history.push(
                      `/${images[j]?.artistDetails?.fullName}/portfolio?imageURL=${images[j]?.artistImage}`
                    );
                  }}
                >
                  {images[j]?.artistDetails?.firstname}{" "}
                  {images[j]?.artistDetails?.lastname}
                </div>
              </div>
              <div className="accessButtonsContainer">
                <img
                  src={crossSvg}
                  alt="Delete"
                  className="accessButtons"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeValueFromMoodItem(
                      element._id,
                      element.itemValue,
                      moodBoardItemIndex
                    );
                  }}
                />
              </div>
            </div>
          );
        }
      }
    }

    return (
      <div
        key={element._id}
        className="moodBoardElementGrid"
        style={{
          gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
          width: "95%",
          minHeight: "auto",
        }}
      >
        {orderMode && (
          <div className="orderButtonContainer miniVersion">
            <div>
              <img
                src={upArrow}
                alt="up"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, true);
                }}
              />
              <img
                src={downArrow}
                alt="down"
                className=" orderElementArrow"
                onClick={() => {
                  handleChangeElementOrder(moodBoardItemIndex, false);
                }}
              />
            </div>
          </div>
        )}
        {items}
        <div
          className="accessButtonsContainer"
          style={{
            top: "0px",
            right: "-30px",
          }}
        >
          <img
            src={crossSvg}
            alt="Delete"
            className="accessButtons accessButtonMain"
            onClick={() => {
              setSelectedMoodBoardItem(element);
              setDeleteModalActive(true);
            }}
          />
        </div>
      </div>
    );
  };
  const handleChangeText = (text) => {
    text = text !== "<p><br></p>" ? text : "";
    setTextElement(text);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { color: ["black", "red", "blue", "green"] },
      ],
      ["link"],
      ["clean"],
    ],
  };
  const handleCancelTextElement = async (id, index) => {
    try {
      setTextElement(""); // Clear the text editor

      setSelectedMoodboard((prev) => ({
        ...prev,
        moodBoardItems: id
          ? prev.moodBoardItems.map((item) =>
              item._id === id ? { ...item, itemType: "text" } : item
            )
          : prev.moodBoardItems.filter((_, idx) => idx !== index),
      }));
    } catch (error) {
      console.error("Error deleting element", error);
    }
  };

  const handleDeleteElement = async (id) => {
    try {
      setSelectedMoodboard((prev) => ({
        ...prev,
        moodBoardItems: prev.moodBoardItems.filter((item) => item._id !== id),
      }));
      setDeleteModalActive(false);
      await deleteMoodBoardItem({
        userId: agentData._id,
        moodBoardId: selectedMoodboard._id,
        itemId: id,
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();
    } catch (error) {
      console.error("Error deleting element", error);
    }
  };
  const removeValueFromMoodItemBanner = async (itemId) => {
    try {
      const response = await addMoodBoardItemValue({
        userId: agentData._id,
        moodBoardId: selectedMoodboard._id,
        itemId: itemId,
        itemValue: "",
      });
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();
      dispatch(updateOpen(true));
      dispatch(updateMessage("Item Successfully Removed"));
    } catch (error) {
      console.error("Error removing item", error);
    }
  };
  const handleTextEditOpen = () => {
    const textElement = {
      itemType: "textEdit",
    };
    setSelectedMoodboard((prev) => ({
      ...prev,
      moodBoardItems: [...prev.moodBoardItems, textElement],
    }));
  };

  const handleEditTextEditOpen = (element) => {
    setTextElement(element.itemValue);

    setSelectedMoodboard((prev) => ({
      ...prev,
      moodBoardItems: prev.moodBoardItems.map((item) =>
        item._id === element._id ? { ...item, itemType: "textEdit" } : item
      ),
    }));
  };
  // Update text element in moodBoard
  const handleTextConfirm = async (id) => {
    try {
      if (id) {
        await addMoodBoardItemValue({
          userId: agentData._id,
          moodBoardId: selectedMoodboard._id,
          itemId: id,
          itemValue: textElement,
        });
      } else {
        await addItemWithValueToMoodBoard({
          userId: agentData._id,
          moodBoardId: selectedMoodboard._id,

          itemType: "text",

          itemValue: textElement,
        });
      }
      await dispatch(AgentDataAPI({ _id: agentData._id }));
      await fetchSharedIdeaBoards();

      setTextElement(""); // Clear the text editor
    } catch (error) {
      console.error("Error updating element", error);
    }
  };
  const handleAddAgent = async () => {
    setShareSuccess("");
    setShareFailed("");
    setAddingAgent(true);
    try {
      const response = await addShareEditEmailToMoodBoard({
        userId: agentData._id,
        moodBoardId: selectedMoodboard._id,
        email: agentEmail,
      });
      if (response && response.data) {
        if (response.data.success) {
          await dispatch(AgentDataAPI({ _id: agentData._id }));
          await fetchSharedIdeaBoards();
          setShareSuccess(response.data.message);
        } else {
          setShareFailed(response.data.message);
        }
      }
      setAgentEmail("");
    } catch (error) {
      console.error("Error adding new element", error);
    } finally {
      setAddingAgent(false);
    }
  };
  return (
    <div style={{ position: "absolute", top: "30px", right: "7%" }}>
      <img
        src={
          !moodboardPopup
            ? "/assets/images/menuWithAddOption.svg"
            : "/assets/images/x.svg"
        }
        style={{
          backgroundColor: "white",
          padding: "4px",
          width: "40px",
          borderRadius: "5px",
        }}
        onClick={handleToggleModal}
      />
      <div>
        <div
          className="CustomSidebar"
          style={{
            position: "fixed",
            left: "0",
            top: "0",
            zIndex:"1000",
            width: "350px",
            height: "100vh",
            backgroundColor: "#fff",
            boxShadow: "-2px 0px 10px rgba(0,0,0,0.2)",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            overflowY: "auto",
            transition: "transform 0.3s ease-in-out, opacity 0.3s ease-in-out",
            transform: moodboardPopup ? "translateX(0)" : "translateX(-100%)",
            opacity: moodboardPopup ? 1 : 0,
            pointerEvents: moodboardPopup ? "auto" : "none", // Prevent interactions when hidden
          }}
        >
          {/* Header with Close Button */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom:"10px"
            }}
          >
            <h3 style={{ color: "rgb(250, 142, 55)" }}>Add Image to MyIdea Board</h3>
            <button
              onClick={() => setMoodboardPopup(false)}
              style={{
                background: "none",
                border: "none",
                fontSize: "20px",
                cursor: "pointer",
              }}
            >
              ✖
            </button>
          </div>

          {/* Main Content */}
          <div>
            {loaderPDF && (
              <div
                style={{
                  position: "absolute",
                  width: "87%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: "10",
                  padding: "200px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "30px",
                  }}
                >
                  <img
                    alt="loading"
                    src={loadingUpdate}
                    style={{ width: "40px" }}
                  />
                  <p>Please wait. Your PDF is being generated.</p>
                </div>
              </div>
            )}
            {levelmoodboardPopup === 0 && (
              <>
                <button
                  className="talentbutton"
                  style={{
                    fontSize: "14px",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  onClick={() => setLevelmoodboardPopup(1)}
                >
                  Create a New MyIdea Board
                </button>

                <div>
                  <div style={{display:"flex"}}>
                    <h4 style={{ color: "rgb(250, 142, 55)", marginBottom: "10px",}}>
                      MyIdea Boards
                    </h4>
                    <Link to={`/myshannon/${agentData._id}/?activeTab=ideaBoard`}>
                      <img width="20px" style={{marginLeft:"5px"}} src={expandIcon}/>
                    </Link>
                  </div>
                  <p>Select existing MyIdea Board to add an image</p>
                  {reverseMoodboards.slice(0,(showMoreIdeaBoards?reverseMoodboards.length:5)).map((moodboard,i) => (
                    <div
                      key={moodboard._id}
                      className="CustomDropdownItem"
                      style={{
                        borderBottom: "solid 1px #B0B0B0",
                        padding: "10px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelectedMoodboard(moodboard)}
                    >
                      {moodboard.title}
                    </div>
                  ))}
                  {
                    !showMoreIdeaBoards &&
                    <p onClick={()=>setShowMoreIdeaBoards(true)} style={{cursor:"pointer",color:"#d16a27",textAlign:"center",paddingTop:"15px"}} >Show more...</p>
                  }
                </div>

                <div>
                  <h4 style={{ color: "rgb(250, 142, 55)", margin: "10px 0" }}>
                    MyShared Idea Boards
                  </h4>
                  {sharedMoodBoards.map((moodboard) => (
                    <div
                      key={moodboard._id}
                      className="CustomDropdownItem"
                      style={{
                        borderBottom: "solid 1px #B0B0B0",
                        padding: "10px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => handleSelectedMoodboard(moodboard)}
                    >
                      {moodboard.title}
                    </div>
                  ))}
                </div>
              </>
            )}

            {/* Create New Board */}
            {levelmoodboardPopup === 1 && (
              <>
                <button
                  className="mr-2"
                  onClick={() => setLevelmoodboardPopup(0)}
                >
                  <img src={leftArrow} alt="back" />
                </button>
                <h4
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "20px" }}
                >
                  Create New MyIdea Board
                </h4>
                <input
                  type="text"
                  className="text-field text_h w-input"
                  placeholder="Enter MyIdea board title"
                  value={newmoodboardName}
                  required
                  onChange={handleNewMoodboard}
                  style={{
                    width: "100%",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                />
                <button
                  className="talentbutton"
                  style={{ fontSize: "10px", width: "100%" }}
                  onClick={handleCreateMoodboard}
                >
                  CREATE
                </button>
              </>
            )}

            {/* Moodboard Items */}
            {levelmoodboardPopup === 2 && selectedMoodboard && (
              <>
                <button
                  className="mr-2 mt-2"
                  onClick={() => setLevelmoodboardPopup(0)}
                >
                  <img src={leftArrow} alt="back" />
                </button>
                <h4 style={{ color: "rgb(250, 142, 55)" }}>
                  Editing: {selectedMoodboard.title}
                </h4>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  {orderMode ? (
                    <>
                      <button
                        onClick={handleSaveMoodboard}
                        className="talentbutton"
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      >
                        SAVE
                      </button>
                      <button
                        onClick={handleOrderClose}
                        className="talentbutton"
                        style={{ marginRight: "5px", fontSize: "12px" }}
                      >
                        CANCEL
                      </button>
                    </>
                  ) : (
                    <div className="buttonActionContainer">
                      <div>
                        <button
                          onClick={handleOrder}
                          className="talentbutton"
                          style={{ marginRight: "5px", fontSize: "12px" }}
                        >
                          ORDER
                        </button>
                        <button
                          className="talentbutton"
                          style={{ marginRight: "5px", fontSize: "12px" }}
                          onClick={() => setShareModalActive(true)}
                        >
                          SHARE
                        </button>
                        <button
                          className="talentbutton"
                          style={{ marginRight: "5px", fontSize: "12px" }}
                          onClick={() => setEditAccess(true)}
                        >
                          EDIT ACCESS
                        </button>
                      </div>

                      <div>
                        <button
                          className="talentbutton"
                          style={{ marginRight: "5px", fontSize: "12px" }}
                          onClick={handleDownloadPDF}
                        >
                          PRINT PDF
                        </button>
                        <button
                          className="talentbutton"
                          style={{ marginRight: "5px", fontSize: "12px" }}
                          onClick={handleGoToIdeaBoard}
                        >
                          Go to IdeaBoard
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="ideaboardMainDiv">
                  {selectedMoodboard.moodBoardItems.map((element, i) => {
                    if (element.itemType === "textEdit") {
                      return (
                        <div key={i}>
                          <ReactQuill
                            value={textElement}
                            onChange={handleChangeText}
                            modules={modules}
                            style={{ marginBottom: "50px" }}
                          />
                          <button
                            class="mx-1 myBtn active sm mt-3 mb-3"
                            onClick={() => handleTextConfirm(element._id)}
                          >
                            {" "}
                            CONFIRM{" "}
                          </button>
                          <button
                            class="mx-1 myBtn sm mt-3 mb-3"
                            onClick={() => {
                              handleCancelTextElement(element._id, i);
                            }}
                          >
                            {" "}
                            Cancel{" "}
                          </button>
                        </div>
                      );
                    }
                    if (element.itemType === "text") {
                      return (
                        <div
                          key={i}
                          className=""
                          style={{
                            position: "relative",
                            padding: ".5px 0 0 0 ",
                            overflow: "hidden",
                          }}
                        >
                          {orderMode && (
                            <div className="orderButtonContainer miniVersion">
                              <div>
                                <img
                                  src={upArrow}
                                  alt="up"
                                  className=" orderElementArrow"
                                  onClick={() => {
                                    handleChangeElementOrder(i, true);
                                  }}
                                />
                                <img
                                  src={downArrow}
                                  alt="down"
                                  className=" orderElementArrow"
                                  onClick={() => {
                                    handleChangeElementOrder(i, false);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          <div
                            className="BioDetailDescriptionPortfolio"
                            dangerouslySetInnerHTML={{
                              __html: element.itemValue,
                            }}
                            style={{ marginBottom: "30px", marginTop: "30px" }}
                          ></div>
                          <div className="accessButtonsContainer">
                            <img
                              src={editButton}
                              alt="Edit"
                              className="accessButtons"
                              onClick={() => handleEditTextEditOpen(element)}
                            />
                            <img
                              src={crossSvg}
                              alt="Unfavorite"
                              className="accessButtons"
                              onClick={() => {
                                setSelectedMoodBoardItem(element);
                                setDeleteModalActive(true);
                                console.log("first");
                              }}
                            />
                          </div>
                        </div>
                      );
                    }
                    if (element.itemType === "upload") {
                      return renderBannerItem(element, i);
                    }
                    if (element.itemType === "grid_1") {
                      return renderGridItem(element, 1, i);
                    }
                    if (element.itemType === "grid_2") {
                      return renderGridItem(element, 2, i);
                    }
                    if (element.itemType === "grid_3") {
                      return renderGridItem(element, 3, i);
                    }
                    if (element.itemType === "grid_4") {
                      return renderGridItem(element, 4, i);
                    }
                  })}
                </div>

                <div>
                  <div className="elementaryContainerMini">
                    <div className="elementaryBoxMini">
                      <img
                        className="elementaryBoxIconMini elementaryLibraryIconMini"
                        src={elementLibraryIcon}
                      />
                      <p className="elementaryBoxTextMini">ADD ELEMENT</p>
                    </div>
                    <div className="elemtaryElementsContainerMini">
                      <div
                        onClick={() => handleTextEditOpen()}
                        className="elemtaryElementsBoxMini"
                      >
                        <img className="elementaryBoxIconMini" src={textIcon} />
                        <div>
                          <p className="elementaryBoxTextMini">TEXT</p>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSelectedElement("grid_1")}
                        className="elemtaryElementsBoxMini"
                      >
                        <img
                          className="elementaryBoxIconMini"
                          src={photoIcon}
                        />
                        <div>
                          <p className="elementaryBoxTextMini">
                            FULL WIDTH IMAGE
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSelectedElement("grid_2")}
                        className="elemtaryElementsBoxMini"
                      >
                        <img
                          className="elementaryBoxIconMini columns2IconMini"
                          src={columns2Icon}
                        />
                        <div>
                          <p className="elementaryBoxTextMini">
                            2 IMAGE COLUMN GRID
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSelectedElement("grid_3")}
                        className="elemtaryElementsBoxMini"
                      >
                        <img
                          className="elementaryBoxIconMini columns3IconMini"
                          src={columns3Icon}
                        />
                        <div>
                          <p className="elementaryBoxTextMini">
                            3 IMAGE COLUMN GRID
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSelectedElement("grid_4")}
                        className="elemtaryElementsBoxMini"
                      >
                        <img
                          className="elementaryBoxIconMini columns3IconMini"
                          src={columns4Icon}
                        />
                        <div>
                          <p className="elementaryBoxTextMini">
                            4 IMAGE COLUMN GRID
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={() => handleSelectedElement("upload")}
                        className="elemtaryElementsBoxMini"
                      >
                        <img
                          className="elementaryBoxIconMini"
                          src={imageUploadIcon}
                        />
                        <div>
                          <p className="elementaryBoxTextMini">IMAGE UPLOAD</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* Create New Moodboard Item (Opens Second Dropdown) */}
            {levelmoodboardPopup === 3 && (
              <>
                <button
                  className="mr-2"
                  onClick={() => setLevelmoodboardPopup(2)}
                >
                  <img src={leftArrow} alt="back" />
                </button>
                <h4
                  style={{ color: "rgb(250, 142, 55)", marginBottom: "20px" }}
                >
                  Create New Moodboard Item
                </h4>
                <div
                  className="CustomDropdownItem"
                  style={{
                    borderBottom: "solid 1px #B0B0B0",
                    padding: "10px 0",
                    textAlign: "left",
                    cursor: "pointer",
                  }}
                  onClick={handleOpenSecondDropdown}
                >
                  GRID
                </div>
              </>
            )}
          </div>

          {/* Second Dropdown (Columns Selection) */}
          {showSecondDropdown && (
            <div
              className="CustomDropdownMenu"
              style={{
                position: "absolute",
                right: "420px", // Adjust based on sidebar width
                top: "110px",
                background: "#fff",
                boxShadow: "0px 2px 10px rgba(0,0,0,0.2)",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <h4 style={{ marginBottom: "10px" }}>Columns</h4>
              <div
                className="CustomDropdownItem"
                onClick={() => handleSelectedElement("grid_1")}
                style={{ padding: "5px", cursor: "pointer" }}
              >
                1 Column
              </div>
              <div
                className="CustomDropdownItem"
                onClick={() => handleSelectedElement("grid_2")}
                style={{ padding: "5px", cursor: "pointer" }}
              >
                2 Columns
              </div>
              <div
                className="CustomDropdownItem"
                onClick={() => handleSelectedElement("grid_3")}
                style={{ padding: "5px", cursor: "pointer" }}
              >
                3 Columns
              </div>
              <div
                className="CustomDropdownItem"
                onClick={() => handleSelectedElement("grid_4")}
                style={{ padding: "5px", cursor: "pointer" }}
              >
                4 Columns
              </div>
            </div>
          )}
        </div>
      </div>
      {editAccess && (
        <MyPopup
          BackClose
          onClose={() => setEditAccess(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Added Agents in {selectedMoodboard.title}
            </h2>

            <div className="alreadyAddedEmails">
              {selectedMoodboard &&
              selectedMoodboard.sharedEditEmails &&
              selectedMoodboard.sharedEditEmails.length > 0 ? (
                <ul className="agentEmailList">
                  {selectedMoodboard.sharedEditEmails.map((email, index) => (
                    <li key={index} className="agentEmailItem">
                      {email}
                      <button
                        className="removeAgentButton"
                        onClick={() => handleRemoveAgent(index)}
                        disabled={removingEmailIndex === index}
                      >
                        {removingEmailIndex === index
                          ? "Removing..."
                          : "Remove"}
                      </button>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="noClientsMessage">No Client added</p>
              )}
            </div>

            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn mx-1"
                onClick={() => setEditAccess(false)}
              >
                Close
              </button>
            </div>
          </div>
        </MyPopup>
      )}
      {shareModalActive && (
        <MyPopup
          BackClose
          onClose={() => setShareModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Share "{selectedMoodboard.title}" Idea Board (View Only)
            </h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <input
                type="text"
                disabled
                value={`${baseUrl}/myshannon/${agentData._id}/?activeTab=ideaBoard&ideaBoardId=${selectedMoodboard._id}`}
                className="copyLink"
              />
              <img
                src={copyButton}
                alt="copy"
                className="simpleAccessButtons"
                onClick={() => handleCopyLink()}
              />
            </div>
            {copySuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {copySuccess}
              </div>
            )}
            <br />
            OR
            <br />
            <h2 style={{ color: "#D16A27" }}>
              Share "{selectedMoodboard.title}" to collaborate,
            </h2>
            <div style={{ marginBottom: "15px" }}>
              <label htmlFor="agentEmail">Email Address:</label>
              <input
                type="email"
                id="agentEmail"
                placeholder="Enter email"
                value={agentEmail}
                onChange={(e) => setAgentEmail(e.target.value)}
                className="emailInput"
              />
              <br />
              <button
                className={`allArtistsButtons allArtistsButtonsActive agentButton`}
                onClick={handleAddAgent}
                disabled={addingAgent}
              >
                {addingAgent ? "Adding..." : "Add Collaborator"}
              </button>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <input
                type="text"
                disabled
                value={`${baseUrl}/myshannon/${agentData._id}/?activeTab=sharedIdeaBoard&ideaBoardId=${selectedMoodboard._id}`}
                className="copyLink"
              />
              <img
                src={copyButton}
                alt="copy"
                className="simpleAccessButtons"
                onClick={() => handleCopyLinkShare()}
              />
            </div>
            {copySuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {copySuccess}
              </div>
            )}
            {shareSuccess && (
              <div style={{ color: "green", margin: "10px" }}>
                {shareSuccess}
              </div>
            )}
            {shareFailed && (
              <div style={{ color: "red", margin: "10px" }}>{shareFailed}</div>
            )}
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn mx-1"
                onClick={() => setShareModalActive(false)}
              >
                Close
              </button>
            </div>
          </div>
        </MyPopup>
      )}
      {deleteModalActive && (
        <MyPopup
          BackClose
          onClose={() => setDeleteModalActive(false)}
          whiteCard={true}
        >
          <div className="mx-5 my-2">
            <h2 style={{ color: "#D16A27" }}>
              Delete {selectedMoodBoardItem.itemName} MoodBoard Item
            </h2>
            <p>Are you sure you want to delete this moodBoard Item?</p>
            <div className="mx-5 my-2 d-flex align-items-center justify-content-center">
              <button
                className="myBtn active mx-1"
                onClick={() => handleDeleteElement(selectedMoodBoardItem._id)}
              >
                DELETE
              </button>
              <button
                className="myBtn mx-1"
                onClick={() => setDeleteModalActive(false)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </MyPopup>
      )}
    </div>
  );
};

export default AddToMoodBoardButton;
